import React, { useEffect, useState } from 'react';
import storeData from './StoreData.json'; // Import your JSON data
import Modal from 'react-modal'; // Import the Modal component


// Set the app element for accessibility
Modal.setAppElement('#root');

const StoreTable = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        region: '',
        storeName: '',
        doors: '',
        width: '',
        height: '',
        media: '',
        location: '',
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedVideo, setSelectedVideo] = useState('');

    useEffect(() => {
        setData(storeData);
    }, []);

    // Function to handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    // Function to filter data based on filters
    const filteredData = data.filter(item => {
        return (
            (!filters.region || item.Region.toLowerCase().includes(filters.region.toLowerCase())) &&
            (!filters.storeName || item.StoreName.toLowerCase().includes(filters.storeName.toLowerCase())) &&
            (!filters.doors || item.Doors.toLowerCase().includes(filters.doors.toLowerCase())) &&
            (!filters.width || item["Width mm"].toString().includes(filters.width)) &&
            (!filters.height || item["Height mm"].toString().includes(filters.height)) &&
            (!filters.media || item.Media.toLowerCase().includes(filters.media.toLowerCase())) &&
            (!filters.location || item.Location.toLowerCase().includes(filters.location.toLowerCase()))
        );
    });

    // Function to open the modal with the selected image
    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage('');
    };

    const openVideoModal = (video) => {
        setSelectedVideo(video);
        setModalVideoIsOpen(true);
    };

    // Function to close the modal
    const closeVideoModal = () => {
        setModalVideoIsOpen(false);
        setSelectedVideo('');
    };

    return (
        <div>
            <style>
                {`
                .highlight-row {
                background-color: #f4dfc8 !important;}
                `}
            </style>
            <h2>Store Data</h2>
            <table className="table table-striped" style={{ width: '100%', textAlign: 'center', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid black' }}>
                            Region
                            <input
                                type="text"
                                name="region"
                                placeholder="Filter by Region"
                                value={filters.region}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                             Desc
                            <input
                                type="text"
                                name="storeName"
                                placeholder="Filter by Desc"
                                value={filters.storeName}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                            Doors
                            <input
                                type="text"
                                name="doors"
                                placeholder="Filter by Doors"
                                value={filters.doors}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                            Width mm
                            <input
                                type="text"
                                name="width"
                                placeholder="Filter by Width"
                                value={filters.width}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                            Height mm
                            <input
                                type="text"
                                name="height"
                                placeholder="Filter by Height"
                                value={filters.height}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                            Media
                            <input
                                type="text"
                                name="media"
                                placeholder="Filter by Media"
                                value={filters.media}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>
                            Location
                            <input
                                type="text"
                                name="location"
                                placeholder="Filter by Location"
                                value={filters.location}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </th>
                        <th style={{ border: '1px solid black' }}>Images</th>
                        <th style={{ border: '1px solid black' }}>Map</th>
                        <th style={{ border: '1px solid black' }}>Videos</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                     <tr
                     key={index}
                     style={{
                       backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent'
                     }}
                    // className={item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? 'highlight-row' : ''}
                   >
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item.Region}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item.StoreName}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item.Doors}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item["Width mm"]}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item["Height mm"]}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item.Media}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>{item.Location}</td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>
                           {item.images.map((image, imgIndex) => (
                               <img
                                   key={imgIndex}
                                   src={image}
                                   alt={`Store ${item.StoreName} Image ${imgIndex + 1}`}
                                   style={{
                                       width: '50px',
                                       height: '50px',
                                       objectFit: 'cover',
                                       cursor: 'pointer',
                                       marginRight: '5px',
                                   }}
                                   onClick={() => openModal(image)} // Open modal on click
                               />
                           ))}
                       </td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>
                           {item.latitude}, {item.longitude}
                       </td>
                       <td style={{ border: '1px solid black', backgroundColor: item.StoreName && item.StoreName.toLowerCase().includes('gandola') ? '#f4dfc8' : 'transparent' }}>
                       {item.videos.map((video, vidIndex) => (
              <video
                key={vidIndex}
                src={video}
                alt={`Store ${item.StoreName} Video ${vidIndex + 1}`}
                style={{
                  width: '60px',
                  height: '40px',
                  cursor: 'pointer',
                  marginRight: '5px',
                }}
                controls
                onClick={() => openVideoModal(video)}
                
              />
            ))}
                       </td>
                   </tr>
                   
                    ))}
                </tbody>
            </table>

            {/* Modal for displaying the selected image */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        background: 'none',
                    },
                }}
            >
                <div style={{ position: 'relative' }}>
                    <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'white', border: 'none', cursor: 'pointer' }}>Close</button>
                </div>
            </Modal>
            <Modal
                isOpen={modalVideoIsOpen}
                onRequestClose={closeVideoModal}
                contentLabel="Video Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        background: 'none',
                    },
                }}
            >
                <div style={{ position: 'relative' }}>
                    <video src={selectedVideo} controls alt="Selected Video" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    <button onClick={closeVideoModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'white', border: 'none', cursor: 'pointer' }}>Close</button>
                </div>
            </Modal>
        </div>
    );
};

export default StoreTable;